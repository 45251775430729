<template>
  <v-layout row wrap>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="timesheet && selectedHourbooking == null">
      <v-tabs
        centered
        grow
        color="primary"
        dark
        icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#previoustab" @click="getTimesheet(previoustab)">
          {{previoustab}}
          <v-icon>arrow_back</v-icon>
        </v-tab>
        <v-tab href="#currenttab" @click="getTimesheet(0)">
          {{currenttab}}
          <v-icon>calendar_today</v-icon>
        </v-tab>
        <v-tab href="#nexttab" @click="getTimesheet(nexttab)">
          {{nexttab}}
          <v-icon>arrow_forward</v-icon>
        </v-tab>
        <v-tab-item value="currenttab">
          <v-list class="pa-0" v-if="days.length > 0">
            <v-list-group
              v-for="day in days"
              v-model="day.active"
              :key="day.date"
              no-action
              :class="day.isWeekend ? '' : day.totalHours == 0 ? 'error' : day.totalHours < 8 ? 'warning' : 'success'"
            >
              <v-list-tile slot="activator">
                <v-list-tile-action>
                  <v-icon v-if="day.active" color="primary">arrow_forward</v-icon>
                </v-list-tile-action>                
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ day.title }}
                    <span class="right">{{ day.totalHours }}u</span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <template 
                v-for="(booking, index) in day.hourbookings">
              <v-list-tile
                class="secondary lighten-1 bookingTile"
                :key="booking.id"
                v-touch="{
                  left: () => deleteHourbooking(booking),
                  down: () => copyHourbooking(booking)
                }"                
                @click="selectHourbooking(booking)">
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ booking.project.organization.name }}                      
                    {{ booking.project.name }}                      
                    <span class="right">{{ booking.amount }}u</span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider dark
                :key="booking.id + '-' + index"
              ></v-divider>
              </template>        
            </v-list-group>
            <v-list-tile class="primary">
              <v-list-tile-content>
                <v-list-tile-title>
                  Totaal
                  <span class="right pr-5">{{ this.workedHours }}u</span>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>              
          </v-list>
        </v-tab-item>
      </v-tabs>

      <v-btn 
        fixed
        dark
        fab
        bottom
        right
        color="primary"
        @click="addHourbooking">
        <v-icon>add</v-icon>
      </v-btn>

    </v-flex>
    <hourBookingForm
      v-if="selectedHourbooking != null"
      :hourbookingdata="selectedHourbooking"
      v-on:done="getTimesheet(currenttab)"
      v-on:reset="getTimesheet(currenttab)"
      v-on:deleteHourbooking="deleteHourbooking(selectedHourbooking)">
    </hourBookingForm>
  </v-layout>
</template>
<style>
  .v-list__group__items--no-action .v-list__tile { padding-left: 32px !important }
</style>
<script>
import hourBookingForm from '../components/HourBookingForm.vue'
export default {
  name: 'TimeSheet',
  components: {
    hourBookingForm
  },
  data () {
    return {
      mode: 'week',
      showWeekend: false,
      previoustab: null,
      currenttab: null,
      nexttab: null,
      days: [],
      timesheet: null,
      selectedHourbooking: null
    }
  },
  watch: {
    days() {
      if (this.days.findIndex(d => d.active) <= 0) {
        this.days[0].active = true
      }
    }
  },
  computed: {
    workedHours() {
      return this.timesheet.hourBookings.map(b => b.amount).reduce((a, b) => a + b, 0)
    }
  },
  methods: {
    addHourbooking () {
      var date = this.days[0]['date']
      if (this.days.findIndex(d => d.active) >= 0) date = this.days[this.days.findIndex(d => d.active)]['date']
      this.timesheet.hourBookings.push({
        'date': date
      }),
      this.selectHourbooking(this.timesheet.hourBookings[this.timesheet.hourBookings.length-1])
    },
    selectHourbooking(booking) {
      this.selectedHourbooking = booking
    },
    getTimesheet (id) {
      if (id == this.currenttab) {
        var selectedDayIndex = this.days.findIndex(d => d.active)
        if (selectedDayIndex < 0) selectedDayIndex = null
      }
      this.selectedHourbooking = null
      this.$http.get('api/timesheet/' + id)
          .then(request => {
            this.timesheet = request.data
            this.showTimesheet(selectedDayIndex)
          })
          .catch((err) => { console.error(err) }) /* eslint-disable-line no-console */
    },
    deleteHourbooking (hourbooking) {
      this.$http.delete('api/hourbooking/' + hourbooking.id)
          .then(() => {
            this.selectedHourbooking = null
            this.timesheet.hourBookings.splice(this.timesheet.hourBookings.indexOf(hourbooking), 1)
            this.showTimesheet(this.days.findIndex(d => d.active))
          })
          .catch((err) => { console.error(err) }) /* eslint-disable-line no-console */
    },
    copyHourbooking (booking) {
      var nextday = this.$moment(booking.date).add(1, 'days')
      if (nextday.isoWeekday() > 5) nextday = nextday.add(2, 'days')
      booking.date = nextday.format()
      this.timesheet.hourBookings.push(booking),
      this.selectHourbooking(this.timesheet.hourBookings[this.timesheet.hourBookings.length-1])
    },
    showTimesheet (activeIndex) {
      var startMoment = this.$moment(this.timesheet.timesheetStart);
      var endMoment = this.$moment(this.timesheet.timesheetEnd);
      var numDays = endMoment.diff(startMoment, 'days');
      this.previoustab = this.$moment(startMoment).add(-7, 'days').format('GGGG[W]W')
      this.currenttab = startMoment.format('GGGG[W]W')
      this.nexttab = this.$moment(startMoment).add(7, 'days').format('GGGG[W]W')
      var thisDay = startMoment
      this.days = []
      var that = this
      while(this.days.length < numDays) {
        var active = false
        if (activeIndex !== null && this.days.length == activeIndex) active = true
        else if (this.days.findIndex(d => d.active) < 0 && thisDay.isSame(this.$moment(), 'day')) active = true
        var hourBookings = this.timesheet.hourBookings.filter(function(hourbooking) {
          return that.$moment(hourbooking.date).isSame(thisDay, 'day');
        })
        var isWeekend = false
        if (thisDay.isoWeekday() > 5) isWeekend = true
        var totalHours = 0
        hourBookings.forEach(function(booking) {
          totalHours += booking.amount
        })
        var newDay = {
          'date': thisDay.format(),
          'title': thisDay.format('dddd D MMMM'),
          'active': active,
          'isWeekend': isWeekend,
          'hourbookings': hourBookings,
          'totalHours': totalHours
        }
        this.days.push(newDay)
        thisDay = startMoment.add(1, 'days')
      }
    }
  },
  mounted () {
    if (this.currenttab == null) {
      this.mode = 'week'
      this.previoustab = this.$moment().isoWeekday(-7).format('GGGG[W]W')
      this.currenttab = this.$moment().format('GGGG[W]W')
      this.nexttab = this.$moment().isoWeekday(7).format('GGGG[W]W')
    }
    this.getTimesheet(this.currenttab)
  }
}
</script>
